<template>
  <div class="guide container">
    <div class="panel">
      <div class="tit">
        <img src="@/assets/image/tit-guide.png" width="100%">
        <span>游客须知</span>
      </div>
      <div class="content">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      content: ''
    }
  },
  mounted () {
    const that = this
    that.$http.get('/v1/articles/' + process.env.VUE_APP_GUIDE_ID).then(res => {
      that.content = res.data.content
    })
  }
}
</script>
<style lang="scss">
.guide {
  .content {
    border-color: #936D5F;

    .section:first-of-type {
      margin-top: 10px;
    }

    .section {
      margin-top: 30px;

      .title {
        font-size: 14px;
        color: #111111;
        letter-spacing: 0;
        text-align: left;
        padding-bottom: 4px;
        font-weight: bold;
      }

      p {
        margin-top: 20px;
        font-size: 13px;
        color: #000000;
        letter-spacing: 0;
        text-align: left;
        line-height: 20px;
      }

      .blue {
        color: #496EA2;
      }
    }
  }
}
</style>
